.assessment-table .ag-center-cols-container {
  min-width: 100%;
}

.assessment-table .ag-header-cell,
.assessment-table .ag-cell {
  padding: 0 10px 0 40px !important;
}

.assessment input {
  border: 0;
  border-bottom: 1px solid transparent;
  color: black;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.assessment input::placeholder {
  color: black;
}

.assessment input:hover,
.assessment input:focus {
  outline: none;
  border-color: black;
}

.assessment {
  margin-left: 10px;
}

.assessment_title {
  font-size: 36px;
  width: 100%;
  margin-bottom: 20px;
}

.assessment_percentage,
.assessment_description {
  font-size: 19px;
  margin-bottom: 10px;
  width: 100%;
  margin-left: 16px;
}

.assessment_percentage {
  margin-bottom: 24px;
}

.assessment_question_title {
  font-size: 16px;
}

.assessment_question_title_view {
  font-family: sans-serif;
}

.assessment_question {
  width: 100%;
}

.assessment_questions {
  min-height: 120px;
}

.assessment_plus_icon {
  height: 40px;
  width: 40px;
  padding: 0;
}

.assessment_questions_add {
  position: absolute;
  right: 0;
  top: 0;
}

.assessment_questions_add p {
  font-size: 24px;
  font-weight: 900;
  margin: 0;
  line-height: 16px;
  text-align: center;
  color: black;
  cursor: pointer;
}

.assessment_questions_add svg {
  cursor: pointer;
}

.assessment_checkbox_view,
.assessment_radio_view {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #0062d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment_checkbox_view.active .assessment_checkbox_view_box,
.assessment_radio_view.active .assessment_radio_view_circle {
  background: #0062d9;
}

.assessment_checkbox_view_box,
.assessment_radio_view_circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: transparent;
}

.assessment_checkbox_view_box,
.assessment_checkbox_view {
  border-radius: 0;
}
